import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    zone: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'hermes.zone',
        maxlength: 16,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    tariffZone: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'hermes.tariffZone',
        maxlength: 16,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: false
      },
      rules: []
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
